<template>
	<section class="section-welcome section-welcome_page_about" id="welcome">
		<div class="section-welcome__content">
			<div class="section-welcome__title-wrapper">
				<h1 class="section-welcome__title">
					{{ $t('We build the future of the web') }}
				</h1>
			</div>
		</div>

		<article class="section-welcome__content-text">
			<p>
				{{ $t('At DexNet, our mission is to revolutionize the internet by providing secure, private, and decentralized services through our innovative P2P network. We aim to combat centralization and enhance online privacy and security by leveraging Dex Node devices that ensure encrypted, fragmented data storage and transmission. Our ecosystem rewards node owners with DexNet tokens, incentivizing the provision of bandwidth, storage, and computing resources.') }}
			</p>
			<p>
				{{ $t('We aspire to create a robust infrastructure that supports a range of decentralized services — from cloud storage and mobile operations to trading platforms and gaming — all accessible through our native token. By prioritizing user privacy and decentralization, DexNet is committed to shaping the future of a free and secure internet for everyone.') }}
			</p>
		</article>
	</section>
</template>
<script>
import anime from 'animejs';

export default {
	name: 'LndWelcome2',
	mounted() {
		anime({
			targets: '.section-welcome',
			background: [
				`
					linear-gradient(180deg, rgba(0, 0, 0, 0.00) 72%, rgba(0, 0, 0, 0.50) 100%),
					linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%),
					radial-gradient(36.91% 47.32% at 19.44% 61.35%, rgba(103, 255, 237, 0.25) 0%, rgba(103, 255, 237, 0.00) 100%),
					radial-gradient(42.38% 54.33% at 81.91% 56.4%, rgba(101, 107, 255, 0.30) 0%, rgba(101, 107, 255, 0.00) 100%),
					radial-gradient(29.88% 38.31% at 50% 72.42%, rgba(49, 206, 255, 0.25) 0%, rgba(49, 206, 255, 0.00) 100%)
				`,
				`
					linear-gradient(180deg, rgba(0, 0, 0, 0.00) 72%, rgba(0, 0, 0, 0.50) 100%),
					linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%),
					radial-gradient(36.91% 47.32% at 19.44% 61.35%, rgba(101, 107, 255, 0.30) 0%, rgba(101, 107, 255, 0.00) 100%),
					radial-gradient(42.38% 54.33% at 81.91% 56.4%, rgba(103, 255, 237, 0.25) 0%, rgba(103, 255, 237, 0.00) 100%),
					radial-gradient(29.88% 38.31% at 50% 72.42%, rgba(49, 206, 255, 0.00) 0%, rgba(49, 206, 255, 0.00) 100%)
				`,
			],
			easing: 'easeInOutQuad',
			duration: 3000,
			loop: true,
			direction: 'alternate'
		});
	}
}
</script>
